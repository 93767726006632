<template>
    <DxTextBox
        v-model="search"
        styling-mode="outlined"
        value-change-event="keyup"
        class="search"
        height="36"
        :width="width"
        placeholder="Tìm kiếm"
        :onContentReady="onTextBoxContentReady"
        @value-changed="reloadAll"
        @enter-key="Search"
        field-template="field"
        :buttons="searchButton"
    />
</template>
<script>
import { DxTextBox } from "devextreme-vue";
export default {
    components: {
        DxTextBox,
    },
    props: {
        readOnly: { type: Boolean, default: false },
        width: { type: String, default: "300" },
    },
    data() {
        return {
            search: "",
            searchButton: [
                {
                    name: "search",
                    location: "after",
                    options: {
                        icon: "search",
                        type: "normal",
                        onClick: () => {
                            this.$emit("search", this.search);
                        },
                    },
                },
            ],
        };
    },
    methods: {
        Search() {
            this.$emit("search", this.search);
        },
        reloadAll(data) {
            this.$emit("model", this.search);
            if (data.value === "") {
                // this.$emit("search", "");
            }
        },
        onTextBoxContentReady(e) {
            if (!!this.readOnly) {
                e.component.option("inputAttr", { readonly: true });
            }
            // console.log("onDateBoxContentReady -> e", e.component.option());
        },
    },
};
</script>
<style>
.search.dx-texteditor.dx-editor-outlined .dx-placeholder:before {
    padding: 10px 12px !important;
}
.search.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
    padding-left: 12px !important;
    padding: unset !important;
}
</style>
<style scoped>
.search .dx-texteditor-input-container {
    flex-direction: row-reverse;
    display: flex;
}
.search .dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
}
</style>
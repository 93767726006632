<template>
    <div>
        <main-datatable-vue
            :Params="Params"
            v-if="Params.Type == $i18n.t('string.tongcuc')"
        >
            <template v-slot:dx-columnn>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenSo"
                    :group-index="0"
                    :groupCellTemplate="groupCellTemplate"
                    caption="Tên Sở GTVT"
                ></DxColumn>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenLoaiBen"
                    data-type="string"
                    cell-template="tenloaiben-cells"
                    caption="Loại bến xe"
                ></DxColumn>
            </template>
        </main-datatable-vue>
        <main-datatable-vue
            :Params="Params"
            v-else-if="Params.Type == $i18n.t('string.so')"
        >
            <template v-slot:dx-columnn>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenSo"
                    :group-index="0"
                    :groupCellTemplate="groupCellTemplate"
                    caption="Tên Sở GTVT"
                ></DxColumn>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenLoaiBen"
                    :fixed="true"
                    data-type="string"
                    cell-template="tenloaiben-cells"
                    caption="Loại bến xe"
                ></DxColumn>
            </template>
        </main-datatable-vue>
        <main-datatable-vue
            :Params="Params"
            v-else-if="Params.Type == $i18n.t('string.benxe')"
        >
            <template v-slot:dx-columnn></template>
        </main-datatable-vue>
    </div>
</template>
<script>
import MainDatatableVue from "./MainDatatable.vue";
import { DxColumn } from "devextreme-vue/data-grid";
export default {
    props: {
        Params: { type: Object, default: {} },
    },
    components: {
        MainDatatableVue,
        DxColumn,
    },
    data() {
        return {};
    },
    methods: {
        groupCellTemplate(e, col) {
            e.innerText =
                "Sở GTVT " +
                col.value +
                " (" +
                col.summaryItems[0].value +
                " bến xe)";
        },
    },
    computed: {},
};
</script>
<template>
    <div>
        <DxPopover
            :width="300"
            v-model:visible="model.Setting"
            :shading="true"
            target="#button_ThongKeBXCoPM_CaiDatHienThi"
            position="bottom"
            shading-color="rgba(0, 0, 0, 0.5)"
            :show-title="true"
            titleTemplate="titleTemplate"
            :onContentReady="onContentReady"
            :onHidden="onHidden"
        >
            <template #titleTemplate>
                <h2
                    class="font-regular font-18 text-xs-center py-3"
                    style="border-bottom: 1px solid #ddd"
                >
                    Hiển thị loại bến xe
                </h2>
            </template>
            <DxList
                :dataSource="data"
                :show-selection-controls="true"
                v-model:selected-items="selected"
                :onSelectionChanged="onSelectionChanged"
                pageLoadMode="scrollBottom"
                selectAllMode="allPages"
                selection-mode="multiple"
                display-expr="TenLoai"
                value-expr="IdLoaiBenXe"
                id="dxlist_BaoCaoHoatDongCuaBenXe_LoaiBX"
            >
                <template #item="{ data: item }">
                    <p :title="item.TenLoai">{{ item.TenLoai }}</p>
                </template>
            </DxList>
        </DxPopover>
        <DxPopup
            v-model:visible="model.Confirm"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="false"
            :width="345"
            height="auto"
        >
            <popup-xac-nhan-vue
                :Params="ParamsConfirm"
                @click="onClickConfirm"
            />
        </DxPopup>
        <!-- Thông báo-->
        <DxPopup
            v-model:visible="model.Dialog"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="false"
            :width="notify.width"
            height="auto"
        >
            <notification-vue :Params="notify" @save="hiddenDialogBack" />
        </DxPopup>
    </div>
</template>
<script>
import PopupXacNhanVue from "@/components/_Common/Popups/PopupXacNhan.vue";
import NotificationVue from "@/components/_Common/Popups/Notification.vue";
import { DxPopover, DxList, DxPopup } from "devextreme-vue";
import $ from "jquery";

export default {
    components: {
        PopupXacNhanVue,
        NotificationVue,
        DxPopover,
        DxList,
        DxPopup,
    },
    props: {
        Model: { type: Number, default: 0 },
    },
    data() {
        return {
            url: this.$i18n.t("url.CaiDatHienThi"),
            url_post: this.$i18n.t("url.ChinhSuaCaiDatHienThi"),
            data: [],
            selected: [],
            flag: false, // Không hiển thị popup xác nhận khi không tương tác với cài đặt
            check: false,
            notify: {},
            model: {
                Setting: false,
                Confirm: false,
                Dialog: false,
            },
            ParamsConfirm: {
                Title: "Xác nhận lưu cài đặt hiển thị 'Loại bến xe'?",
                button_cancel: "HỦY",
                button_save: "ĐỒNG Ý",
            },
        };
    },
    watch: {
        Model() {
            this.model.Setting = true;
        },
    },
    methods: {
        async getData() {
            try {
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url, {
                    GuidCaiDat: this.$i18n.t(
                        "DefaultNumBer.GUIDCaiDatBCTrangThaiTT"
                    ),
                }).Get();
                if (rs.Data.State == "Success") {
                    let sl = null;
                    if (!!rs.Data.Data) {
                        sl = rs.Data.Data?.filter((e) => {
                            return e.IdLoaiBenXe != 0;
                        });
                        let selected = rs.Data.Data.filter(
                            (x) => x.check == true
                        );
                        this.selected = selected;
                        if (selected.length > 0) {
                            this.check = true;
                        }
                    }
                    this.data = sl;
                    this.$emit("result", { Data: sl, Model: 0 });
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: Settings.vue ~ line 114 ~ getData ~ error",
                    error
                );
            }
        },
        async postData() {
            this.$startLoading;
            let noidung = this.selected.map((e) => {
                return { IdLoaiBenXe: e.IdLoaiBenXe, TenLoai: e.TenLoai };
            });
            let rs = await this.$Core.Api.BaoCaoBenXe(this.url_post, {
                GuidCaiDat: this.$i18n.t(
                    "DefaultNumBer.GUIDCaiDatBCTrangThaiTT"
                ),
                noiDung: JSON.stringify(noidung),
            }).Post();
            if (rs.Data.State == "Success") {
                this.setNotify(
                    true,
                    rs.Data.State,
                    rs.GetMessage(),
                    'Lưu cài đặt hiển thị "Loại bến xe" thành công!',
                    "324"
                );
            } else {
                this.setNotify(
                    true,
                    rs.Data.State,
                    rs.GetMessage(),
                    'Lưu cài đặt hiển thị "Loại bến xe" thất bại!',
                    "443"
                );
            }
            this.$stopLoading;
        },
        onContentReady(e) {
            var html = e.component.content();
            $(html).css("padding", "8px");
        },
        onHidden() {
            this.model.Setting = false;
            if (this.flag == true) {
                this.model.Confirm = true;
                this.flag = false;
            }
        },
        onSelectionChanged() {
            if (this.check == true) {
                this.check = false;
                return;
            }
            this.flag = true;
            console.log("onItemCli", this.flag);
        },
        onClickConfirm(param) {
            if (param) {
                this.postData();
            } else {
                this.getData();
            }
            this.model.Confirm = false;
        },
        setNotify(dialog, state, message, message_title, width) {
            let data = {
                state: state,
                message_title: message_title,
                message: message,
                width: width,
            };
            this.notify = data;
            this.model.Dialog = dialog;
        },
        hiddenDialogBack() {
            this.model.Dialog = false;
            this.getData();
        },
    },
    created() {
        this.getData();
    },
};
</script>
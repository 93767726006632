<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table-trangthai-truyentai-dlbx"
        ref="table_trangthai_truyentai_dlbx"
        :show-borders="true"
        :data-source="Params.DataTable"
        :remote-operations="true"
        @cellHoverChanged="onCellHoverChanged"
        :onCellPrepared="onCellPrepared"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
        key-expr="idBen"
        :column-auto-width="true"
        height="calc(100vh - 169px)"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn
            alignment="left"
            :fixed="true"
            data-field="TenBen"
            caption="Tên bến xe"
            data-type="string"
            :minWidth="250"
            :header-filter="{ dataSource: dataSourceFilter }"
        ></DxColumn>
        <slot name="dx-columnn"></slot>

        <DxColumn
            :allowFiltering="false"
            data-field="SoNgayKhongTruyenTai"
            caption="Số ngày không truyền tải"
            data-type="string"
        ></DxColumn>
        <DxColumn
            :caption="'Tháng ' + month"
            alignment="center"
            :allowFiltering="false"
        >
            <DxColumn
                :allowFiltering="false"
                :allowSorting="false"
                v-for="index in days_in_month"
                :key="index"
                :data-field="
                    'DanhSachTrangThaiTruyenTai[' + (index - 1) + '].TruyenTai'
                "
                :caption="index < 10 ? '0' + index : '' + index"
                width="56"
                alignment="center"
                data-type="int"
                cell-template="trangthai-cells"
                cssClass="bg-color-parent"
            ></DxColumn>
        </DxColumn>
        <DxSummary>
            <DxGroupItem
                column="TenSo"
                summary-type="count"
                display-format="{0} bến xe"
            />
        </DxSummary>
        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="Detail"
            caption="Trạng thái"
            width="1"
            cell-template="buttons-cell"
        ></DxColumn>
        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.BieuDoBaoCaoNhatKyTruyenTai')
                        )
                    "
                    @click="XemChiTiet(data.data)"
                ></DxButton>
            </div>
        </template>
        <template #tenloaiben-cells="{ data }">
            <div class="tenloai-css">{{ data.data.TenLoaiBen }}</div>
        </template>
        <template #trangthai-cells="{ data }">
            <div :class="data.value == -1 ? 'bg-color' : ''">
                <i
                    :class="
                        data.value == 1
                            ? 'mdi mdi-check-circle-outline'
                            : data.value == 0
                            ? 'mdi mdi-close-circle-outline'
                            : ''
                    "
                    :style="
                        data.value == 1
                            ? 'color:#13B000'
                            : data.value == 0
                            ? 'color:#D10909'
                            : ''
                    "
                ></i>
            </div>
        </template>
    </DxDataGrid>
</template>

<script>
import moment from "moment";
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxSummary,
    DxGroupItem,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxDataGrid,
        DxButton,
        DxColumn,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxSummary,
        DxGroupItem,
    },
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {
            month: moment(new Date()).format("MM/yyyy"),
            days_in_month: moment(new Date(), "YYYY-MM").daysInMonth(),
        };
    },
    methods: {
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        XemChiTiet(data) {
            localStorage.setItem("IdSo", data.idSo);
            localStorage.setItem("IdBenXe", data.idBen);
            this.$router.push({
                path: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/Bieu-Do",
                query: { Date: this.Params.Date },
            });
        },
        dataSourceFilter(data) {
            let self = this;
            data.dataSource.postProcess = (results) => {
                results = self.Params.DataBen;
                return results;
            };
        },
        onCellPrepared(e) {
            if (e.rowType == "data") {
                if (e.value == -1) {
                    e.cellElement.style.backgroundColor = "#EEEEEE";
                }
            }
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element: self.$refs.table_trangthai_truyentai_dlbx.instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid = self.$refs.table_trangthai_truyentai_dlbx.instance;
            datagrid.clearFilter();
        });
        this.emitter.on("days-in-month", function ({ number, month }) {
            self.days_in_month = number;
            self.month = month;
        });
    },
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>


<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="5"] {
    text-align: right !important;
}
>>> .tenloai-css {
    border: 1px solid #000;
    border-radius: 50%;
    width: 22px;
    margin: 0 auto;
    text-align: center;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>
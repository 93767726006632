<template>
    <DxDropDownBox
        v-model:value="model"
        :data-source="dataSource"
        display-expr="TenLoai"
        value-expr="IdLoaiBenXe"
        :dropDownOptions="{ onContentReady: onContentReady, width: '120%' }"
        stylingMode="underlined"
    >
        <template #content="{ data }">
            <DxList
                :dataSource="dataSource"
                :show-selection-controls="true"
                v-model:selected-items="selectedLoaiBX"
                pageLoadMode="scrollBottom"
                selectAllMode="allPages"
                :search-enabled="true"
                search-mode="contains"
                search-expr="TenLoai"
                selection-mode="all"
                display-expr="TenLoai"
                value-expr="IdLoaiBenXe"
                id="dxlist_BaoCaoHoatDongCuaBenXe_LoaiBX"
                :onSelectionChanged="selected"
            >
                <template #item="{ data: item }">
                    <p :title="item.TenLoai">
                        {{ item.TenLoai }}
                    </p>
                </template>
            </DxList>
        </template>
    </DxDropDownBox>
</template>
<script>
import { DxDropDownBox, DxList } from "devextreme-vue";
export default {
    components: {
        DxDropDownBox,
        DxList,
    },
    props: {
        Params: {
            type: Object,
            default: () => ({
                SearchEnable: true,
                Sync: false,
            }),
        },
    },
    data() {
        return {
            url: this.$i18n.t("url.DungChungDanhSachLoaiBenXe"),
            model: 0,
            dataSource: [],
            selectedLoaiBX: [],
        };
    },
    methods: {
        async getData() {
            try {
                this.$startLoading;
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url).Get();
                if (rs.Data.State == "Success") {
                    let sl = [];
                    if (!!rs.Data.Data) {
                        sl = rs.Data.Data.filter((e) => {
                            return e.IdLoaiBenXe != 0;
                        });
                    }
                    this.selectedLoaiBX = rs.Data.Data;
                    this.model = ["Tất cả"];
                    this.dataSource = sl;

                    this.$emit("result", { Data: sl, Model: 0 });
                }
            } catch (e) {
                console.log(
                    "🚀 ~ file: GetLoaiBenXe.vue ~ line 64 ~ getData ~ e",
                    e
                );
            } finally {
                this.$stopLoading;
            }
        },
        getDataWithPromise() {
            let self = this;
            return new Promise(async function (resolve, reject) {
                let rs = await self.$Core.Api.BaoCaoBenXe(self.url).Get();
                resolve(rs);
                reject(null);
            });
        },
        selected(data) {
            let data_props = null;
            let inputWrapper = document.querySelectorAll(
                ".dx-dropdowneditor-input-wrapper"
            );
            let input = inputWrapper[inputWrapper.length - 1].children[0];
            let divInput = inputWrapper[inputWrapper.length - 1].children[1];
            if (this.selectedLoaiBX.length === this.dataSource.length) {
                input.style.fontSize = "16px";
                input.style.border = "none";
                input.style.outline = "none";
                input.style.lineHeight = "30px";
                input.setAttribute("type", "text");
                this.model = ["Tất cả"];
                divInput.style.display = "none";
                data_props = 0;
            } else {
                let id_dv = this.selectedLoaiBX.map((e) => {
                    return e.IdLoaiBenXe;
                });
                input.setAttribute("type", "hidden");
                divInput.style.display = "block";

                this.model = id_dv;
                data_props = id_dv;
            }
            this.$emit("result", { Data: this.dataSource, Model: data_props });
        },
        onContentReady(e) {
            var html = e.component.content();
            html.style.padding = "8px";
        },
        onClosed() {
            this.$emit("result", { Data: this.dataSource, Model: this.model });
        },
    },
    created() {
        if (!this.Params.Sync) {
            this.getData();
        } else {
            let rs = this.getDataWithPromise();
            this.$emit("result", rs);
        }
    },
    mounted() {
        let inputWrapper = document.querySelectorAll(
            ".dx-dropdowneditor-input-wrapper"
        );
        let input = inputWrapper[inputWrapper.length - 1].children[0];
        let divInput = inputWrapper[inputWrapper.length - 1].children[1];
        input.style.fontSize = "16px";
        input.style.border = "none";
        input.style.outline = "none";
        input.style.lineHeight = "30px";
        input.type = "text";
        divInput.style.display = "none";
    },
};
</script>
<style scoped>
.custom-input {
    font-size: 16px !important;
    outline: none !important;
    border: none !important;
}
</style>
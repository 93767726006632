<template>
    <div>
        <!-- ==================>Thông báo thành công<===================== -->
        <div v-if="Params.state == 'Success'">
            <div class="text-xs-center">
                <i
                    class="mdi"
                    :class="icon"
                    :style="'color:' + color"
                    style="font-size: 46px"
                ></i>
            </div>
            <h1 class="font-regular text-xs-center font-medium font-20">
                {{ message_title }}
            </h1>
            <p class="text-xs-center py-2">{{ message }}</p>
            <div class="text-xs-center">
                <DxButton
                    :text="button"
                    width="83"
                    style="text-transform: uppercase; color: white"
                    :style="'background-color:' + color"
                    @click="click(true)"
                ></DxButton>
            </div>
        </div>
        <!-- ==================>Thông báo lỗi<===================== -->
        <div v-if="Params.state == 'Error'">
            <h1
                class="font-medium font-20"
                style="text-transform: uppercase"
                :style="'color:' + color"
            >
                {{ title }}
            </h1>
            <div class="row pt-3">
                <div class="pr-2">
                    <i
                        class="mdi"
                        :class="icon"
                        style="font-size: 56px"
                        :style="'color:' + color"
                    ></i>
                </div>
                <div class="xs10">
                    <div
                        :class="
                            !message_title || !message
                                ? 'column justify-center'
                                : ''
                        "
                    >
                        <div class="font-medium font-16">
                            {{ message_title }}
                        </div>
                        <div class="font-16">{{ message }}</div>
                    </div>
                </div>
            </div>
            <div class="row justify-end">
                <DxButton
                    :text="button"
                    width="83"
                    style="text-transform: uppercase; color: white"
                    :style="'background-color:' + color"
                    @click="click(true)"
                ></DxButton>
            </div>
        </div>
        <!-- ==================>Thông báo cảnh báo<===================== -->
        <div v-if="Params.state == 'Warning'">
            <div class="row jutify-end">
                <div class="xs3 pr-2"></div>
                <div class="xs10">
                    <h1
                        class="font-medium font-20"
                        style="text-transform: uppercase"
                        :style="'color:' + color"
                    >
                        {{ title }}
                    </h1>
                </div>
            </div>
            <div class="row mt-4">
                <div class="xs3 pr-2">
                    <i
                        class="mdi"
                        :class="icon"
                        style="font-size: 80px"
                        :style="'color:' + color"
                    ></i>
                </div>
                <div class="xs10">
                    <div class="font-medium font-16">{{ message_title }}</div>
                    <div class="font-16">{{ message }}</div>
                </div>
            </div>
            <div class="row justify-space-between mt-3">
                <DxButton
                    :text="button_cancel"
                    width="83"
                    style="text-transform: uppercase"
                    styling-mode="text"
                    type="default"
                    @click="click(false)"
                ></DxButton>
                <DxButton
                    :text="button_save"
                    style="text-transform: uppercase; color: white"
                    type="default"
                    @click="click(true)"
                ></DxButton>
            </div>
        </div>
    </div>
</template>
<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        Params: {
            type: Object,
            default: {
                state: "Error",
                title: "Thông báo!",
                icon: "mdi-alert-circle",
                color: "#D10909",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
                button: "ok",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
            },
        },
    },
    data() {
        return {
            title: this.Params.title,
            icon: this.Params.icon,
            message_title: this.Params.message_title,
            message: this.Params.message,
            color: this.Params.color,
            button: this.Params.button,
            button_save: this.Params.button_save,
            button_cancel: this.Params.button_cancel,
        };
    },
    methods: {
        error() {
            this.title = this.Params.title || "Thông báo!";
            this.icon = this.Params.icon || "mdi-alert-circle";
            this.color = this.Params.color || "#D10909";
            this.button = this.Params.button || "ok";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        success() {
            this.color = this.Params.color || "#13B000";
            this.button = this.Params.button || "ok";
            this.title = this.Params.title;
            this.icon = this.Params.icon || "mdi-check-circle-outline";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        warning() {
            this.title = this.Params.title || "Cảnh báo";
            this.icon = this.Params.icon || "mdi-alert";
            this.color = this.Params.color || "#FB8C00";
            this.button_save = this.Params.button_save || "Đồng ý";
            this.button_cancel = this.Params.button_cancel || "Hủy";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        click(params) {
            if (!params) {
                this.$emit("cancel", params);
            } else {
                this.$emit("save", params);
            }
        },
    },
    watch: {
        Params: {
            handler: function (val) {
                switch (this.Params.state) {
                    case "Success":
                        this.success();
                        break;
                    case "Warning":
                        this.warning();
                        break;
                    case "Error":
                        this.error();
                        break;
                    default:
                        this.icon = "mdi-alert-circle";
                        this.color_icon = "warning";
                        this.color_button = "warning";
                        this.color_text = "warning";
                        this.title = "Đã xảy ra lỗi !";
                        break;
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
</style>
<template>
    <DxPopup
        :visible="Model"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :full-screen="true"
        :shading="true"
        width="100vw"
        height="100vh"
        shadingColor="rgb(33,33,33,.26)"
    >
        <div
            class="row justify-center align-center"
            style="height: 100vh; background-color: transparent; opacity: 0.65"
        >
            <img src="@/assets/icon/loadding.gif" alt />
        </div>
    </DxPopup>
</template>
<script>
import { DxPopup } from "devextreme-vue";
import $ from "jquery";
export default {
    components: {
        DxPopup,
    },
    props: {
        Model: { type: Boolean, default: false },
    },
    methods: {
        onContentReady(args) {
            var html = args.component.content();
            $(html).css("padding", "0");
            $(html).css("box-shadow", "unset");
            $(html).parent().css("background", "transparent");
            $(html).parent().css("box-shadow", "unset");
        },
    },
};
</script>
<style>
</style>



<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxToolbar-DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxToolbar-DxItem>
            <DxToolbar-DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_ThongKeBXCoPM_Timkiem"
                    />
                </template>
            </DxToolbar-DxItem>
        </DxToolbar>
        <div class="pl-3">
            <div
                class="row justify-space-between"
                style="border-bottom: 1px solid #ddd"
            >
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center pt-2 pb-4">
                        <div class="row align-center pt-2">
                            <span
                                class="mr-2 font-16"
                                id="label_ThongKeBXCoPM_Tu"
                                >Từ</span
                            >
                            <DxDateBox
                                v-model="model.DateFrom"
                                displayFormat="dd/MM/yyyy"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                id="datebox_ThongKeBXCoPM_TuNgay"
                                class="datebox-baocao"
                                :useMaskBehavior="true"
                            >
                                <DxValid-DxValidator>
                                    <DxValid-DxRequiredRule
                                        message="Không được để trống!"
                                    />
                                    <DxValid-DxRangeRule
                                        :max="maxDate"
                                        :message="
                                            $i18n.t('DefaultString.MaxDate')
                                        "
                                    />
                                    <DxValid-DxRangeRule
                                        :min="minDate"
                                        :message="
                                            $i18n.t('DefaultString.MinDate')
                                        "
                                    />
                                    <DxValid-DxRangeRule
                                        :max="model.DateTo"
                                        message="Từ ngày phải nhỏ hơn đến ngày"
                                    />
                                </DxValid-DxValidator>
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                            </DxDateBox>
                            <span
                                style="
                                    border: 1px solid #707070;
                                    height: 2px;
                                    width: 10px;
                                "
                                class="mx-2"
                            ></span>
                            <DxDateBox
                                v-model="model.DateTo"
                                displayFormat="dd/MM/yyyy"
                                validationMessageMode="always"
                                dropDownButtonTemplate="customIcon"
                                id="datebox_ThongKeBXCoPM_DenNgay"
                                class="datebox-baocao"
                                :useMaskBehavior="true"
                            >
                                <DxValid-DxValidator>
                                    <DxValid-DxRequiredRule
                                        message="Không được để trống!"
                                    />
                                    <DxValid-DxRangeRule
                                        :max="maxDate"
                                        :message="
                                            $i18n.t('DefaultString.MaxDate')
                                        "
                                    />
                                    <DxValid-DxRangeRule
                                        :min="minDate"
                                        :message="
                                            $i18n.t('DefaultString.MinDate')
                                        "
                                    />
                                    <DxValid-DxRangeRule
                                        :min="model.DateFrom"
                                        message="Từ ngày phải lớn hơn đến ngày"
                                    />
                                </DxValid-DxValidator>
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                            </DxDateBox>
                            <DxButton
                                class="ml-3"
                                :icon="$i18n.t('icon.search')"
                                text="Lọc"
                                :rtlEnabled="true"
                                styling-mode="outlined"
                                id="button_ThongKeBXCoPM_Loc"
                                @click="validate"
                            />
                        </div>
                    </div>
                </DxValidationGroup>
                <div v-if="data_from_api.DataStatus.length > 0">
                    <div
                        class="row pr-1"
                        :class="
                            data_from_api.DataStatus.length % 2 != 0
                                ? 'pt-3'
                                : ''
                        "
                    >
                        <div style="width: 100%">
                            <div
                                class="row align-center justify-end"
                                style="padding-top: 5px"
                            >
                                <span class="pr-3">Ghi chú:</span>
                                <div style="min-width: 410px">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in data_from_api.DataStatus"
                                        :key="index"
                                        :style="
                                            data_from_api.DataStatus.length <= 3
                                                ? ''
                                                : index % 2 == 0
                                                ? 'float:left'
                                                : ''
                                        "
                                    >
                                        <i
                                            :class="$i18n.t('icon.record')"
                                            :style="
                                                'color:#' + item.matrangthai
                                            "
                                            style="vertical-align: middle"
                                        ></i>
                                        <span
                                            class="pr-3"
                                            style="vertical-align: middle"
                                            >{{ item.text }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <datatable-vue :Params="data_from_api" />
            <!-- <loadding-vue :Model="loadding" /> -->
        </div>
    </div>
</template>
<script>
import router from "@/components/_Common/Components/router";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/TrangThaiTruyenTaiDuLieuCuaBenXe/Datatable.vue";
import ToolbarVue from "@/components/BaoCaoThongKe/TrangThaiTruyenTaiDuLieuCuaBenXe/Toolbar.vue";
import LoaddingVue from "@/components/_Common/Components/Loadding.vue";
export default {
    Permission: $PermText("DanhSach", "BaoCaoTrangThaiTruyenTai"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        searchVue,
        DatatableVue,
        LoaddingVue,
    },
    data() {
        return {
            url: this.$i18n.t("url.BCTrangThaiTruyenTaiList"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            url_select_trangthai: this.$i18n.t(
                "url.BCTrangThaiTruyenTaiTrangThai"
            ),
            loadding: false,
            loadding_cout: 0,
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "IDBaoCao",
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            dateValue: new Date().getTime(),
            data_from_api: {
                Type: "BenXe",
                DataSelect: {
                    SelectNhom: [],
                    SelectSo: [],
                    SelectLoaiBX: [],
                },
                DataStatus: [],
                DataTable: null,
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_ThongKeBenXeCoPM_BCHoatDongCuaBenXe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_ThongKeBenXeCoPM_ThongKeBenXeCoPM",
                    text: this.$i18n.t("stringRouter.brancheTKBXCPM"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData(load_capdo) {
            if (!load_capdo) {
                let rs_capdo = await this.getPermission();
                this.data_from_api.Type = rs_capdo || "BenXe";
            }
            let query = {
                tuNgay:
                    new Date(this.model.DateFrom).getTime() * 10000 +
                    621355968000000000,
                denNgay:
                    new Date(this.model.DateTo).getTime() * 10000 +
                    621355968000000000,
                idSos: this.model.IdSos,
                idLoaiBens:
                    this.model.IdLoaiBens.length > 0
                        ? this.model.IdLoaiBens
                        : [0],
                txtTimKiem: this.model.Search || "",
            };
            const store = this.$Helper.Common.table({
                Id: this.id_table,
                Url: this.url,
                Stt: true,
            })
                .CustomStore()
                .Post(query);
            this.data_from_api.DataTable = store.Store;
        },
        async getPermission() {
            let self = this;
            return new Promise(async function (rel) {
                self.loadding_cout++;
                let rs = await this.$Core.Api.BaoCaoBenXe(self.url_capdo).Get();
                if (rs.Data.State == "Success") {
                    rel(rs.Data.Data);
                }
                self.loadding_cout--;
            });
        },
        async getDataStatus() {
            this.loadding_cout++;
            let rs = await this.$Core.Api.BaoCaoBenXe(
                this.url_select_trangthai
            ).Get();
            if (rs.Data.State == "Success") {
                let data = rs.Data.Data.map(
                    ({ MaTrangThai, TenTrangThai, Value = [] }) => ({
                        text: TenTrangThai,
                        value: ["ColorTrangThai", "=", MaTrangThai],
                        template: "color-cells",
                        matrangthai: MaTrangThai,
                    })
                );
                this.data_from_api.DataStatus = data;
            }
            this.loadding_cout--;
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdLoaiBens: [0],
            };
            this.getData();
        },
        searchs(search) {
            this.getData(true);
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            console.log("selectionChange -> data", data);
            this.model.IdLoaiBens = data.SelectLoaiBX;
            this.model.IdSos = data.SelectSo;
        },
        validate(e) {
            let result = this.$refs.formValidation.instance.validate();
            if (!!result.isValid) {
                this.getData(true);
            }
        },
    },
    watch: {
        loadding_cout() {
            this.loadding = this.loadding_cout > 0 ? true : false;
        },
    },
    created() {
        let self = this;
        this.emitter.on("loadding", function (load) {
            load ? self.loadding_cout++ : self.loadding_cout--;
        });
        this.getData();
        this.getDataStatus();
    },
    beforeDestroy() {
        $Core.Event.Offs();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
>>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
}
</style>
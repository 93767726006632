<template>
    <DxSelectBox
        v-model="model"
        :dataSource="{ store: data, paginate: true }"
        display-expr="TenSo"
        value-expr="IdSo"
        :search-enabled="Params.SearchEnable"
        @itemClick="selected"
        :onValueChanged="onValueChanged"
        stylingMode="underlined"
    />
    <!-- :onClosed="selected" -->
</template>
<script>
import { DxSelectBox } from "devextreme-vue";
export default {
    components: {
        DxSelectBox,
    },
    props: {
        Params: {
            type: Object,
            default: () => ({
                SearchEnable: true,
                Sync: false,
            }),
        },
    },
    data() {
        return {
            url: this.$i18n.t("url.DungChungDanhSachSo"),
            model: null,
            data: [],
        };
    },
    methods: {
        async getData() {
            try {
                this.$startLoading;
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url).Get();
                if (rs.Data.State == "Success") {
                    this.data = rs.Data.Data || [];
                    if (this.$route.query.DateFrom) {
                        this.model = this.$route.query.IdSo;
                    } else {
                        this.model =
                            rs.Data.Data != null ? rs.Data.Data[0].IdSo : "";
                    }
                }
                this.$emit("result", { Data: rs.Data.Data, Model: this.model });
            } catch (error) {
                console.log("🚀 ~ error", error);
            } finally {
                this.$stopLoading;
            }
        },
        getDataWithPromise() {
            let self = this;
            return new Promise(async function (resolve, reject) {
                let rs = await this.$Core.Api.BaoCaoBenXe(self.url).Get();
                resolve(rs);
                reject(null);
            });
        },
        selected(data) {
            this.$emit("result", { Data: this.data, Model: this.model });
        },
        onValueChanged(e) {
            if (e.value == null) {
                this.getData();
            }
        },
    },
    created() {
        let self = this;
        this.emitter.on("reload", function (params) {
            self.getData();
        });
        if (!this.Params.Sync) {
            this.getData();
        } else {
            let rs = this.getDataWithPromise();
            this.$emit("result", rs);
        }
    },
};
</script>

<template>
    <div>
        <div class="row justify-center mb-4">
            <h1
                class="font-20 font-regular"
                :style="'width:' + WidthTitle"
                style="text-align: center"
            >
                {{ Title }}
            </h1>
        </div>
        <div class="row justify-space-between">
            <DxButton
                :text="button_cancel"
                style="text-transform: uppercase"
                styling-mode="text"
                type="default"
                @click="click(false)"
            ></DxButton>
            <DxButton
                :text="button_save"
                style="text-transform: uppercase"
                styling-mode="contained"
                type="default"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
</template>
<script>
//  bat buoc selected truyen sang phai co Id
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    data() {
        return {};
    },
    props: {
        Params: {
            type: Object,
            default: {
                WidthTitle: "90%",
                Title: "",
                button_cancel: "HỦY",
                button_save: "ĐỒNG Ý",
            },
        },
    },
    data() {
        return {
            WidthTitle: null,
            Title: this.Params.Title,
            button_cancel: this.Params.button_cancel,
            button_save: this.Params.button_save,
        };
    },
    watch: {
        Params: {
            handler: function (val) {
                this.WidthTitle = val.WidthTitle || "90%";
                this.Title = val.Title || "";
                this.button_cancel = val.button_cancel || "HỦY";
                this.button_save = val.button_save || "ĐỒNG Ý";
            },
            deep: true,
        },
    },
    methods: {
        click(param) {
            this.$emit("click", param);
        },
    },
};
</script>
<style scoped>
</style>
